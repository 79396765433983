// custom functions
var FE = {
	location: window.location,

    HeaderShrink: {
        init: function () {
            window.addEventListener('scroll', function(e) {
                var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                    shrinkOn = 50;
                var pageHeader = document.querySelector('.page-header');

                if (distanceY > shrinkOn) {
                    pageHeader.classList.add('compact');
                } else {
                    pageHeader.classList.remove('compact');
                }
            })
        }
    },

    MarketingStats: {
	    init: function () {
            this.animate();
        },
        animate: function () {

        }
    },

    MobileMenu: {
        burgerIcon: null,
        navMain: null,
        init: function() {
            this.burgerIcon = document.getElementById('burger-icon');
            this.navMain = document.getElementById('nav-main');

            const mobileMenuOverlay = document.createElement('div');
            mobileMenuOverlay.id = 'mobile-menu-overlay';
            mobileMenuOverlay.classList.add('mobile-menu-overlay');
            mobileMenuOverlay.addEventListener('click', FE.MobileMenu.hide);

            document.body.append(mobileMenuOverlay);

            this.burgerIcon.addEventListener('click', function (e) {
                if (FE.MobileMenu.navMain.classList.contains('active')) {
                    FE.MobileMenu.hide();
                } else {
                    FE.MobileMenu.show();
                }
            });
        },
        show: function () {
            FE.MobileMenu.burgerIcon.setAttribute('aria-expanded', 'true');
            FE.MobileMenu.navMain.classList.add('active');
            document.body.classList.add('mobile-menu-open');
        },
        hide: function () {
            FE.MobileMenu.burgerIcon.setAttribute('aria-expanded', 'false')
            FE.MobileMenu.navMain.classList.remove('active');
            document.body.classList.remove('mobile-menu-open');
        }

    }

};

// init custom functions
document.addEventListener("DOMContentLoaded", function(event) {
    FE.HeaderShrink.init();
    FE.MobileMenu.init();

    if(document.querySelector('.marketing-stats')) {
        FE.MarketingStats.init();
    }

});
